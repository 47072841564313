<template>
  <div>
    <configuration-set
      :components="components"
      :speditor="speditor"
      :current-component="currentComponent"
      :loading="loading"
      use-v-form
      @@changeComponent="changeComponent"
      @@save="save"
    >
      <template v-slot:content>
        <ServiceAuthorization
          v-show="currentComponent==='ServiceAuthorization'"
          :is-new="isNew"
        />
      </template>
    </configuration-set>
  </div>
</template>

<script lang="ts">
import { OtherSpeditor } from '@/constants/Speditors/Other/configuration/Other';
import configurationMixin from '@/mixins/configurationMixin';
import ServiceAuthorization from '@/views/Speditors/Other/Forms/ServiceAuthorization.vue';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';

export default {
  components: {
    ConfigurationSet,
    ServiceAuthorization,
  },

  mixins: [configurationMixin],

  data: () => ({
    speditor: 'CustomShipment',
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
    ],
  }),

  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: OtherSpeditor });
    },
  },
};
</script>
