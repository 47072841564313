export const OtherSpeditor = {
  clientId: '',
  configurationName: '',
  password: '',
  tenantId: '',
  courierCode: '',
  skipShipmentGeneration: true,
  requireLabel: true,
  labelSource: 'EXTERNAL_SYSTEM',
  additionalServices: null,
  fieldsMappings: null,
  packagings: null,
  integration: 'CUSTOM_SHIPMENT',
  insurance: null,
};
